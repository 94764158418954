import {
  createRouter,
  createWebHashHistory
} from "vue-router";
import store from '../store'
const routes = [{
    path: "/",
    redirect: "/introduce"
  },
  {
    //自选区投资
    path: "/introduce",
    name: "introduce",
    component: () => import("../page/introduce"),
    meta: {
      auth: false
    }
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../page/index"),
    children: [{
        //钱包
        path: "home",
        name: "home",
        component: () => import("../page/home"),
        meta: {
          auth: true
        }
      },
      {
        //公告
        path: "notice",
        name: "notice",
        component: () => import("../page/notice"),
        meta: {
          auth: true
        }
      },
      {
        //兑换
        path: "swap",
        name: "swap",
        component: () => import("../page/swap"),
        meta: {
          auth: true
        }
      },
      {
        //兑换记录
        path: "swapLog",
        name: "swapLog",
        component: () => import("../page/swapLog"),
        meta: {
          auth: true
        }
      },
      {
        //记录
        path: "assetLog",
        name: "assetLog",
        component: () => import("../page/assetLog"),
        meta: {
          auth: true
        }
      },
      {
        //奖励
        path: "award",
        name: "award",
        component: () => import("../page/award"),
        meta: {
          auth: true
        }
      },
      {
        //投资
        path: "invest",
        name: "invest",
        component: () => import("../page/invest"),
        meta: {
          auth: true
        },
      },
      {
        //投资记录
        path: "investLog",
        name: "investLog",
        component: () => import("../page/investLog"),
        meta: {
          auth: true
        }
      },
      {
        //投资详情
        path: "investment",
        name: "investment",
        component: () => import("../page/investment"),
        meta: {
          auth: true
        }
      },
      {
        //个人中心
        path: "personal",
        name: "personal",
        component: () => import("../page/personal"),
        meta: {
          auth: true
        }
      },
      {
        //提取
        path: "extract",
        name: "extract",
        component: () => import("../page/extract"),
        meta: {
          auth: true
        }
      },
      {
        //团队
        path: "team",
        name: "team",
        component: () => import("../page/team"),
        meta: {
          auth: true
        }
      },
    ]
  },


]


const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})
router.beforeEach((to, from, next) => {
  const account = store.state.account;
  //判断是否有权限返回登录界面
  if (to.meta.auth) {
    if (account) {
      next()
    } else {
      next("/introduce")
    }
  } else {
    next()
  }
})

export default router;