// index.js 调用接口的方法
import { getD, postD } from './api'

class Home {
    static home(parme = {}) {
        return getD('home', parme, true)
    }

    static notice_list(parme = {},isLoad) {
        return getD('notice/index', parme,isLoad)
    }


    static getInvest(parme = {}) {
        return getD('invest/getTime', parme)
    }

    static getTeam(parme = {}) {
        return getD('personal/getTeam', parme)
    }

    static extractLog(parme = {},isLoad) {
        return getD('withdrawal/index', parme,isLoad)
    }

    static extract(parme = {}) {
        return postD('withdrawal/store', parme)
    }
    // 
    static getUser(parme = {}) {
        return getD('personal/getUser', parme)
    }

    static getConst(parme = {}) {
        return getD('getConst', parme)
    }

    static exchange(parme = {}) {
        return postD('exchange/store', parme)
    }

    static exchangeLog(parme = {},isLoad) {
        return getD('exchange/index', parme,isLoad)
    }
    static log(parme = {},isLoad) {
        return getD('log/index', parme,isLoad)
    }
    static lucky(parme = {}) {
        return getD('lucky/info', parme)
    }
    static luckyStore(parme = {}) {
        return postD('lucky/store', parme)
    }
    static investLog(parme = {},isLoad) {
        return getD('invest/index', parme,isLoad)
    }
    static invesTment(parme = {}) {
        return getD('invest/show', parme)
    }

    static repeat(parme = {}) {
        return postD('invest/repeat', parme)
    }
}

export default Home;