<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
import { showToast } from 'vant';
export default {
  name: 'App',
  components: {
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  async mounted() {
    var _this = this
    if (!window.ethereum) {
      return showToast({
        className: "tilp_toast",
        message: _this.$t('Metamask未安装'),
        position: 'bottom',
      });
    }
    // window.ethereum.on("networkChanged", function () {
    //   if (window.ethereum.networkVersion != process.env.VUE_APP_netWork) {
    //     _this.$router.replace('/')
    //     _this.$store.commit('setTabActINdex', '0')
    //   }
    // });
    window.ethereum.on("accountsChanged", async function (accounts) {
      if (accounts[0]) {
        _this.$store.commit('setAccount',accounts[0])
        _this.$router.replace('/index/home')
        _this.$store.commit('setTabActINdex', '0')
        _this.isRouterAlive = false
        _this.$nextTick(() => {
          _this.isRouterAlive = true
        })
      } else {
        _this.$store.commit('setAccount', '')
        _this.$router.replace('/')
      }
    });
  },
  methods: {

  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
