import { showToast } from 'vant';
import i18n from "@/language/index";
const $t = i18n.global.t
const copy = (url) => {
    var input = document.createElement("input"); // js创建一个input输入框
    input.value = url; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    showToast({
        message: $t('复制成功'),
        position: 'bottom',
    });
}
const roundUpToTwoDecimals = (number) => {
    if (number) {
        let amount = Number(number)
        return parseFloat(Math.ceil(amount * 100) / 100);
    }
}

const formdata = (value) => {
    if (value) {
        const start = value.slice(0, 6);
        const end = value.slice(-4);
        return `${start}***${end}`;
    }
};
const getTime = (timestamp) => {
    const date = timestamp ? new Date(timestamp) : new Date(); // 将时间戳转换为毫秒并创建Date对象
    const year = date.getFullYear(); // 获取年份
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，并在前面加0补齐两位数
    const day = ("0" + date.getDate()).slice(-2); // 获取日期，并在前面加0补齐两位数
    const hours = ("0" + date.getHours()).slice(-2); // 获取小时，并在前面加0补齐两位数
    const minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，并在前面加0补齐两位数
    const seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒钟，并在前面加0补齐两位数
    const formattedDate = `${year}-${month}-${day}`; // 格式化日期字符串
    return formattedDate
}
export {
    copy,
    roundUpToTwoDecimals,
    formdata,
    getTime,
}