// http.js封装axios的请求方式(get请求、post请求) 
// 封装axios超时请求时间
// token设置

import axios from 'axios' // 引用axios
import { showLoadingToast, closeToast, showToast } from 'vant';
import store from '@/store'
import md5 from 'js-md5';
import randomString from "../../assets/script/randomString"
// axios 配置
// axios.defaults.timeout = 120000 // 设置接口响应时间
// const baseURL= require(process.env.VUE_APP_baseURL)
axios.defaults.baseURL = process.env.VUE_APP_baseURL +'/api' // 这是调用数据接口,公共接口url+调用接口名
// 增加token（先写了个固定的token，实际项目中应该是通过接口获取到token）
// axios.defaults.headers.common['X-Access-Token'] = store.state.token || "";
// axios.defaults.headers.common['X-Access-uid'] = store.state.uid || "";
// axios.defaults.headers.common['X-Access-lang'] = store.state.lang || "";
axios.interceptors.request.use(
  config => {
    let account = store.state.account
    let lang = store.state.lang
    let randStr = randomString(32);
    let dataStr = account + randStr
    let sign = md5(md5(dataStr.toLowerCase()) + 'J1aF1LIf0WdFigTw')
    if (!config.headers.hasOwnProperty('address') && account) {
      config.headers.address = account
    }
    if (!config.headers.hasOwnProperty('randStr') && randStr) {
      config.headers.randStr = randStr
    }
    if (!config.headers.hasOwnProperty('sign') && sign) {
      config.headers.sign = sign
    }
    if (!config.headers.hasOwnProperty('lang') && lang) {
      config.headers.lang = lang
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';



// 封装get请求
export function get(url, params = {}, isloading = true) {
  if (isloading) {
    showLoadingToast({
      message: '',
      forbidClick: true,
      duration: 0,
      iconSize: '60px',
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(response => {
        closeToast()
        if (response.data.code != 200) {
          showToast({
            message: response.data.msg,
            position: 'bottom',
          });
        }
        resolve(response.data)
      })
      .catch(err => {
        closeToast()
        reject(err)
      })
  })
}
// 封装post请求
export function post(url, data = {}, isloading = true) {
  if (isloading) {
    showLoadingToast({
      message: '',
      forbidClick: true,
      duration: 0,
      iconSize: '60px',
    });
  }
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      response => {
        // console.log(response.data.code)
        closeToast()
        resolve(response.data)
      },
      err => {
        closeToast()
        reject(err)
      }
    )
  })
}