import Vuex from 'vuex'
import CryptoJS from 'crypto-js'
const key = '51ZCa7aerPICsNus';
const state = {
    lang: getLang(window.location.href) || sessionStorage.getItem("lang") || 'en',
    account: getaccount() || "",
    pageTitle: "",
    tabActINdex: sessionStorage.getItem("tabActINdex") || 0,
}
const mutations = {
    setLang(state, data) {
        sessionStorage.setItem("lang", data);
        state.lang = data
    },
    setAccount(state, data) {
        let parme = { account: data };
        let encryptedData = encryptData(JSON.stringify(parme), key);
        sessionStorage.setItem("account", encryptedData);
        state.account = data
    },
    setPageTitle(state, data) {
        state.PageTitle = data
    },
    setTabActINdex(state, data) {
        sessionStorage.setItem("tabActINdex", data);
        state.tabActINdex = data
    },



}

const getters = {

}
function getLang(url) {
    let urlStr = url.split('?')
    for (let i = 0, len = urlStr.length; i < len; i++) {
        if (urlStr[i].indexOf("lang") != -1) {
            let arr = urlStr[i].split('=')
            if (arr[1] == 'cht' || arr[1] == 'kor' || arr[1] == 'jp' || arr[1] == 'ind') {
                return arr[1];
            } else {
                return 'en';
            }

        }
    }
}
// 加密函数
function encryptData(data, key) {
    const encryptedData = CryptoJS.AES.encrypt(data, key);
    return encryptedData.toString();
}

// 解密函数
function decryptData(encryptedData, key) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, key);
    return decryptedData.toString(CryptoJS.enc.Utf8);
}


function getwalletid() {
    // 读取数据
    let encryptedData = sessionStorage.getItem('walletid');
    if (encryptedData) {
        let decryptedData = decryptData(encryptedData, key);
        if (!decryptedData) { return '' }
        let data = JSON.parse(decryptedData);
        return data.walletid || ''
    }
}
function getaccount() {
    // 读取数据
    let encryptedData = sessionStorage.getItem('account');
    if (encryptedData) {
        let decryptedData = decryptData(encryptedData, key);
        if (!decryptedData) { return '' }
        let data = JSON.parse(decryptedData);
        return data.account
    }
}

export default new Vuex.Store({
    state,
    mutations,
    getters
})